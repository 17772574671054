<template>
  <div>
    <div class="page-title">
      <h3>Добавление объекта</h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/objects')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="input-field">
          <select id="customer_id" ref="customer_select" v-model="customer_id"
                  :class="{invalid: $v.customer_id.$error}">
            <option value="" disabled selected>Выбрать</option>
            <option v-for="r in customer_list" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="customer_id">Контрагент</label>
          <small class="helper-text invalid" v-if="$v.customer_id.$error">Не выбран
            контрагент</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="locality" type="text" v-model="locality"
                 :class="{invalid: $v.locality.$error}">
          <label for="locality">Населенный пункт</label>
          <small class="helper-text invalid" v-if="$v.locality.$error">Не указан населенный
            пункт</small>
        </div>
      </div>

      <div class="row">
        <div class="input-field">
          <input id="address" type="text" v-model="address" :class="{invalid: $v.address.$error}">
          <label for="address">Адрес</label>
          <small class="helper-text invalid" v-if="$v.address.$error">Не указан адрес</small>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="location_lat" type="text" v-model="location_lat">
            <label for="location_lat">Местоположение (широта)</label>
            <small class="helper-text invalid" v-if="$v.location_lat.$error">Не указана широта</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="location_lng" type="text" v-model="location_lng">
            <label for="location_lng">Местоположение (долгота)</label>
            <small class="helper-text invalid" v-if="$v.location_lng.$error">Не указана долгота</small>
          </div>
        </div>
      </div>

      <button class="btn waves-effect waves-light blue darken-4" type="submit">
        Сохранить
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
import {required, numeric, decimal} from 'vuelidate/lib/validators'

export default {
  metaInfo() {
    return {
      title: 'Добавление объекта'
    }
  },
  data: () => ({
    loading: true,
    customer_list: [],
    customer_select: null,
    customer_id: null,
    locality: '',
    address: '',
    location_lat: null,
    location_lng: null,
  }),
  validations: {
    customer_id: {required, numeric},
    locality: {required},
    address: {required},
    location_lat: {required, decimal},
    location_lng: {required, decimal},
  },
  async mounted() {
    this.customer_list = await this.$store.dispatch('fetchCustomers')

    this.loading = false

    setTimeout(() => {
      this.customer_select = M.FormSelect.init(this.$refs.customer_select)
      M.updateTextFields()
    })
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      const object = {
        customer_id: this.customer_id,
        locality: this.locality,
        address: this.address,
        location: {"lat": parseFloat(this.location_lat), "lng": parseFloat(this.location_lng)},
      }

      const obj = await this.$store.dispatch('createObject', object)
      await this.$router.push('/objects/' + obj.id)
    },
  },
  destroyed() {
    if (this.customer_select && this.customer_select.destroy) {
      this.customer_select.destroy()
    }
  }
}
</script>

<style scoped>
.switch {
  margin-top: 1rem;
}
</style>
